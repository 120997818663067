import React from "react";
import style from "./style.module.scss";

import Carousel from "components/Carousel/Carousel";

import image1 from "../../assets/TravelSSP/image1.jpg";
import image2 from "../../assets/TravelSSP/image2.jpg";
import image3 from "../../assets/TravelSSP/image3.jpg";
import image4 from "../../assets/TravelSSP/image4.jpg";
import image5 from "../../assets/TravelSSP/image5.jpg";
import image6 from "../../assets/TravelSSP/image6.jpg";
import image7 from "../../assets/TravelSSP/image7.jpg";
import image8 from "../../assets/TravelSSP/image8.jpg";
import image9 from "../../assets/TravelSSP/image9.jpg";
import androidtv from "../../assets/TravelSSP/androidtv.png";
import amazonfiretv from "../../assets/TravelSSP/amazonfiretv.png";
import rokutv from "../../assets/TravelSSP/rokutv.png";

export default function TravelSSP() {
  return (
    <div className={style.wrapper}>
      <div className="spacer" />
      <h1 className={`${style.titleBody} ${style.removeSpace}`}>
        Introducing our CTV Travel
      </h1>
      <div className="spacer" />
      <p className={style.titleBody}>
        Introducing our CTV Travel network A unique platform for up and coming
        and well established creators to share their immersive long-form
        experiences and documentary film.
      </p>
      <p className={style.titleBody}>
        From scenic walking tours through bustling cities, to peaceful train
        rides across breathtaking landscapes, we bring travel to life right in
        your living room.
      </p>
      <p className={style.titleBody}>
        Our network is designed to captivate viewers with high-quality,
        slow-paced journeys that transport them to new destinations, offering a
        perfect blend of exploration and relaxation, making each stream an
        adventure of its own.
      </p>
      <div className="spacer" />
      <a
        className="contained-button"
        href="mailto:theoffice@commodifiedimage.com"
      >
        See How We Can Help
      </a>
      <div className="spacer" />
      <div className="spacer" />
      <div className="spacer" />
      <Carousel
        largeSize
        squareShow
        images={[
          image1,
          image2,
          androidtv,
          amazonfiretv,
          rokutv,
          image3,
          image4,
          image5,
          image6,
          image7,
          image8,
          image9,
        ]}
      />

      <div className="spacer" />
      <div className="spacer" />
    </div>
  );
}
