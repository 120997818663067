import React from "react";
import style from "./style.module.scss";
import { Link } from "react-router-dom";
import Carousel from "components/Carousel/Carousel";

import discord from "../../assets/Marketplace/discord.png";
import facebookads from "../../assets/Marketplace/facebookads.png";
import inmobi from "../../assets/Marketplace/inmobi.png";
import ironsource from "../../assets/Marketplace/ironsource.png";
import rewardnetwork from "../../assets/Marketplace/rewardnetwork.png";
import unity from "../../assets/Marketplace/unity.png";

export default function Marketplace({ hiddeButton = false }) {
  return (
    <div className={style.wrapper}>
      <div className="spacer" />
      <h1 className={`${style.titleBody} ${style.removeSpace}`}>Marketplace</h1>
      <p className={style.titleBody}>
        Unlock Premium Opportunities with Our Open Web, Transparent Marketplace
      </p>
      <p className={style.titleBody}>
        Gain access to exclusive, high-quality, untapped inventory and connect
        with top-tier publishers for exclusive ad placements.
      </p>

      <div className="spacer" />
      <div className="spacer" />
      {!hiddeButton && (
        <>
          <Link className="contained-button">See How We Can Help</Link>
          <div className="spacer" />
        </>
      )}

      <div className="spacer" />
      <Carousel
        images={[
          discord,
          facebookads,
          inmobi,
          ironsource,
          rewardnetwork,
          unity,
        ]}
      />
      <div className="spacer" />
      <div className="spacer" />
      <div className="spacer" />
    </div>
  );
}
