import React, { useState } from "react";
import style from "./style.module.scss";
import { Link, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  const navigation = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [mobileIsOpen, setMobileIsOpen] = useState(false);
  return (
    <div className={style.container}>
      <h2
        className={style.header}
        onClick={() => {
          navigation("/");
        }}
      >
        Commodified media
      </h2>
      <div className={style.navigationContainer}>
        <div
          className={style.mobileIcon}
          onMouseEnter={() => setMobileIsOpen(true)}
          onMouseLeave={() => setMobileIsOpen(false)}
        >
          <FontAwesomeIcon icon={faBars} />
          {mobileIsOpen && (
            <div className={style.mobileDropdownContent}>
              <Link to="/products-mobile" className={style.dropdownButton}>
                Products
              </Link>
              <Link to="/solutions" className={style.dropdownButton}>
                Solutions
              </Link>
              {/* <Link to="/contact" className={style.dropdownButton}>
                Contact
              </Link> */}
              <Link to="/company" className={style.dropdownButton}>
                Company
              </Link>
            </div>
          )}
        </div>

        {/* Navigation menu */}
        <div className={style.menu}>
          {/* Products Link with dropdown functionality */}
          <div
            className={`${style.dropdown} ${style.link}`}
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          >
            <Link to="#" className={style.link}>
              Products
            </Link>
            {isOpen && (
              <div className={style.dropdownContent}>
                <Link to="/products?page=dsp" className={style.dropdownButton}>
                  DSP
                </Link>
                <Link
                  to="/products?page=performance"
                  className={style.dropdownButton}
                >
                  Performance
                </Link>
                <Link
                  to="/products?page=marketplace"
                  className={style.dropdownButton}
                >
                  Marketplace
                </Link>
                <Link
                  to="/products?page=travel"
                  className={style.dropdownButton}
                >
                  Travel SSP
                </Link>
              </div>
            )}
          </div>

          {/* Other menu links without dropdowns */}
          <Link to="/solutions" className={style.link}>
            Solutions
          </Link>
          {/* <Link to="/contact" className={style.link}>
            Contact
          </Link> */}
          <Link to="/company" className={style.link}>
            Company
          </Link>
        </div>
      </div>
    </div>
  );
}
