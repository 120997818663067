import React, { useEffect } from "react";
import style from "./style.module.scss";
import { Link, useLocation } from "react-router-dom";
//import Carousel from "components/Carousel/Carousel";
/*
import img0 from "../../assets/DSP/img0.png";
import img1 from "../../assets/DSP/img1.png";
import img2 from "../../assets/DSP/img2.png";
import img3 from "../../assets/DSP/img3.png";
import img4 from "../../assets/DSP/img4.png";
import img5 from "../../assets/DSP/img5.png";
import img6 from "../../assets/DSP/img6.png";
import img7 from "../../assets/DSP/img7.png";
import img8 from "../../assets/DSP/img8.png";
import img9 from "../../assets/DSP/img9.png";
import img10 from "../../assets/DSP/img10.png";
import img11 from "../../assets/DSP/img11.png";
import img12 from "../../assets/DSP/img12.png";
*/
export default function Dsp({ hiddeButton = false }) {
  const location = useLocation();

  useEffect(() => {
    // Check if we should scroll to the element
    if (location.state?.scrollToElement) {
      const element = document.getElementById("target-element");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div className={style.wrapper} id="target-element">
      <div className="spacer" />
      <h1 className={`${style.titleBody} ${style.removeSpace}`}>DSP</h1>
      <h2 className={style.titleBody}>
        Precision Programmatic Advertising at Scale
      </h2>
      <p className={style.titleBody}>
        Harness our DSP to target audiences with AI-enhanced bidding strategies,
        ensuring precise audience segmentation and dynamic ad placements, all
        backed by internal data analytics.
      </p>
      <div className="spacer" />
      <div className="spacer" />
      {!hiddeButton && (
        <>
          <Link className="contained-button">See How We Can Help</Link>
          <div className="spacer" />
        </>
      )}
      {/* <Carousel
        images={[
          img0,
          img1,
          img2,
          img3,
          img4,
          img5,
          img6,
          img7,
          img8,
          img9,
          img10,
          img11,
          img12,
        ]}
      /> */}
      {/* <div className="spacer" />
      <div className="spacer" /> */}
    </div>
  );
}
