import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";

import Home from "./pages/Home/Home";
import MainPage from "./pages/MainPage/MainPage";
import Product from "./pages/Products/Products";
import Solutions from "./pages/Solutions/Solutions";
import Contact from "./pages/Contacts/Contacts";
import Company from "./pages/Company/Company";
import ProductMobile from "pages/Products/ProductMobile";
import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <BrowserRouter>
      <div className="wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="content">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/products" element={<Product />} />
            <Route path="/products-mobile" element={<ProductMobile />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/company" element={<Company />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
          </Routes>
        </div>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
