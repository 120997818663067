import React, { useState, useEffect, useRef } from "react";
import style from "./style.module.scss";

const AnimatedNumber = ({ targetString, duration = 2000 }) => {
  const [currentNumber, setCurrentNumber] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [hasStartedCounting, setHasStartedCounting] = useState(false);
  const elementRef = useRef(null);
  const steps = 100; // Number of steps for the animation

  // Extract the numeric part and the rest of the string (e.g., 100 billion -> 100 and " billion")
  const extractNumberAndSuffix = (targetString) => {
    const regex = /(\d+\.?\d*)/; // Matches the numeric part
    const match = targetString.match(regex);

    const number = match ? parseFloat(match[0]) : 0; // Extracted number
    const suffix = match ? targetString.replace(match[0], "") : ""; // Remainder of the string as suffix
    return { number, suffix };
  };

  const { number: targetNumber, suffix } = extractNumberAndSuffix(targetString);

  useEffect(() => {
    const currentElement = elementRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setTimeout(() => {
            setIsVisible(true);
            observer.disconnect();
          }, 100); // 0.5 second delay before starting animation
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (observer && currentElement) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    setHasStartedCounting(true); // Start the wind effect when counting begins
    const stepTime = duration / steps;
    const increment = targetNumber / steps;
    let stepCount = 0;

    const interval = setInterval(() => {
      stepCount += 1;
      setCurrentNumber((prev) => Math.min(prev + increment, targetNumber));

      if (stepCount >= steps) {
        clearInterval(interval);
      }
    }, stepTime);

    return () => clearInterval(interval);
  }, [isVisible, targetNumber, duration]);

  return (
    <h3 ref={elementRef} className={hasStartedCounting ? style.windEffect : ""}>
      {Math.round(currentNumber)}
      {suffix}
    </h3>
  );
};

export default AnimatedNumber;
