import React from "react";
import Dsp from "../../components/Products/Dsp";
import Performance from "../../components/Products/Performance";
import Marketplace from "../../components/Products/Marketplace";
import TravelSSP from "../../components/Products/TravelSSP";

export default function ProductMobile() {
  return (
    <div>
      <Dsp />
      <Performance />
      <Marketplace />
      <TravelSSP />
    </div>
  );
}
