import React from "react";
import style from "./style.module.scss";

import prebid from "assets/Company/prebid.png";
import vast from "assets/Company/vast.png";
import js from "assets/Company/js.png";
import ortb from "assets/Company/ortb.png";

export default function Technology() {
  return (
    <div className={style.container}>
      <h3>Technology</h3>

      <p>Ways to integrate </p>
      <div className="spacer" />
      <div className={style.counterContainer}>
        <div className={style.counter}>
          <p>Prebid</p>
          <img
            className={style.customImageSize}
            src={prebid}
            alt="Prebid"
          ></img>
        </div>
        <div className={style.counter}>
          <p>oRTB</p>
          <img className={style.customImageSize} src={ortb} alt="Ortb"></img>
        </div>
        <div className={style.counter}>
          <p> VAST</p>{" "}
          <img className={style.customImageSize} src={vast} alt="VAST"></img>
        </div>
        <div className={style.counter}>
          <p>JS tags</p>{" "}
          <img className={style.customImageSize} src={js} alt="JS tags"></img>
        </div>
      </div>
      <div className="spacer" />
    </div>
  );
}
