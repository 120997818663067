import React from "react";
import style from "./style.module.scss";

export default function Contact() {
  return (
    <div className={style.container}>
      <div className="spacer" />
      <h1 className={style.titleNoSpacer}>Contact</h1>
    </div>
  );
}
