import React from "react";
import style from "./style.module.scss";
import { Link, useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  const handleScrollToMainPage = (e) => {
    e.preventDefault(); // Prevent default link behavior

    // Navigate to MainPage and pass state for scrolling
    navigate("/", { state: { scrollToElement: true } });
  };

  return (
    <div className={style.container}>
      <h3>Commodified Image</h3>
      <div className={style.wrapper}>
        <div className={style.linkContainer}>
          <Link onClick={handleScrollToMainPage} className={style.link}>
            Products
          </Link>
          <Link to="/solutions" className={style.link}>
            Solutions
          </Link>
          {/* <Link to="/contact" className={style.link}>
            Contact
          </Link> */}
          <Link to="/company" className={style.link}>
            Company
          </Link>
          <Link to="/terms-and-conditions" className={style.link}>
            Terms And Conditions
          </Link>
        </div>
      </div>
    </div>
  );
}
