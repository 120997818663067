import React from "react";
import style from "./style.module.scss";

import prebid from "assets/Company/prebid.png";
import vast from "assets/Company/vast.png";
import js from "assets/Company/js.png";
import ortb from "assets/Company/ortb.png";

export default function Company({ hideTechnology = false }) {
  return (
    <div className={style.container}>
      <div className="spacer" />
      <h1 className={style.titleNoSpacer}>Company</h1>

      <h3>About</h3>
      <p className={style.titleBody}>
        Commodified Media is where deep industry knowledge meets cutting-edge
        innovation. Founded by a crew of veteran media buyers and developers,
        we’ve spent years not just keeping up with the digital advertising game,
        but shaping it. With our insider expertise and future-forward mindset,
        we design performance-driven solutions that don’t just optimize ad
        campaigns—they transform them
      </p>
      <p className={style.titleBody}>
        We’ve built a platform that bridges cutting-edge technology and industry
        insight to deliver powerful, performance-driven solutions for
        advertisers and publishers alike
      </p>
      <div className="spacer" />
      <h3 className={style.titleBody}>Our mission</h3>
      <p className={style.titleBody}>
        We’re on a mission to arm our partners with cutting-edge programmatic
        tools that turn digital advertising into an optimized,
        performance-focused engine for growth and awareness
      </p>
      <div className="spacer" />
      <h3 className={style.titleBody}>Our values</h3>
      <h4 className={style.titleBody}>Integrity</h4>
      <p className={style.titleBody}>
        We believe trust is the foundation of every strong partnership. That’s
        why we operate with complete honesty and transparency, ensuring our
        actions align with our words. we build relationships that stand the test
        of time.
      </p>
      <div className="spacer" />
      <h4 className={style.titleBody}>Innovation</h4>
      <p className={style.titleBody}>
        Innovation drives everything we do. We constantly explore new
        technologies that deliver cutting-edge solutions that keep our partners
        ahead of the competition. we believe in continuous evolution in the
        digital landscape.
      </p>
      <div className="spacer" />
      <h4 className={style.titleBody}>Accountability</h4>
      <p className={style.titleBody}>
        We don’t just aim for success, we take ownership of it. We hold
        ourselves accountable to the highest standards, Every commitment we make
        is backed by our promise to deliver measurable outcomes. ensuring every
        decision and action drives value for our partners.
      </p>
      <div className="spacer" />
      <div className="spacer" />
      <h3>The Leadership Team</h3>
      <ul>
        <li className={style.listSpace}>Jonathan Storch / Founder</li>
        <li className={style.listSpace}>Daniel M / Head of publishing</li>
        <li className={style.listSpace}>Armando G / Full stack developer</li>
        <li className={style.listSpace}>Tal M / Programmatic Buyer</li>
        <li className={style.listSpace}>Noa L / Data analyst</li>
      </ul>
      <div className="spacer" />
      <div className="spacer" />
      <h3>We Are Hiring</h3>
      <ul>
        <li className={style.listSpace}>
          Programmatic Account Manager - Remote
        </li>
      </ul>
      <div className="spacer" />
      <div className="spacer" />
      {!hideTechnology && (
        <>
          <h3>Technology</h3>

          <p>Ways to integrate </p>
          <div className="spacer" />
          <div className={style.counterContainer}>
            <div className={style.counter}>
              <p>Prebid</p>
              <img
                className={style.customImageSize}
                src={prebid}
                alt="Prebid"
              ></img>
            </div>
            <div className={style.counter}>
              <p>oRTB</p>
              <img
                className={style.customImageSize}
                src={ortb}
                alt="Ortb"
              ></img>
            </div>
            <div className={style.counter}>
              <p> VAST</p>{" "}
              <img
                className={style.customImageSize}
                src={vast}
                alt="VAST"
              ></img>
            </div>
            <div className={style.counter}>
              <p>JS tags</p>{" "}
              <img
                className={style.customImageSize}
                src={js}
                alt="JS tags"
              ></img>
            </div>
          </div>
          <div className="spacer" />
        </>
      )}
    </div>
  );
}
