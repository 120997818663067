import React from "react";
import style from "./style.module.scss";

export default function TermsAndConditions() {
  return (
    <div className={style.wrapper}>
      <div className="spacer" />
      <h3 className={style.titleBody}>Privacy Policy</h3>
      <h3 className={style.titleBody}>
        Last Updated: <span>10/1/2024</span>
      </h3>
      <h2 className={style.main}>1. Information We Collect</h2>
      <p className={style.maxWidth}>
        We may collect personal information in several ways, including:
      </p>
      <ul className={style.maxWidth}>
        <li>
          <span className={style.mainContainerText}>
            Information You Provide to Us:
          </span>
          When you contact us or use our services, you may provide us with
          personal details such as your name, email address, company name, phone
          number, and other information relevant to your inquiry or service use.
        </li>
        <div className="spacer-xs" />
        <li>
          <span className={style.mainContainerText}>
            Information Collected Automatically:
          </span>
          When you visit our Website, we may collect information automatically
          through cookies or similar technologies, such as your IP address,
          browser type, device information, operating system, and browsing
          activities.
        </li>
        <div className="spacer" />
        <li>
          <span className={style.mainContainerText}>
            Third-Party Information:
          </span>
          We may receive information about you from third parties, such as
          partners or service providers, that help us deliver services.
        </li>
      </ul>

      <h2 className={style.main}>2. How We Use Your Information</h2>
      <p className={style.maxWidth}>
        We use the information we collect for the following purposes:
      </p>
      <ul className={style.maxWidth}>
        <li>
          <span className={style.mainContainerText}>
            To Provide and Improve Our Services:
          </span>
          We use your personal information to process your inquiries, manage
          your account, and improve our Website and services.
        </li>
        <div className="spacer-xs" />
        <li>
          <span className={style.mainContainerText}>
            To Communicate with You:
          </span>
          We may use your contact information to send updates, notifications, or
          marketing communications (with your consent, where required by law).
        </li>
        <div className="spacer" />
        <li>
          <span className={style.mainContainerText}>
            To Analyze and Personalize Your Experience:
          </span>
          We may use your information to understand how users interact with our
          Website and to provide content that is relevant to you.
        </li>
        <div className="spacer" />
        <li>
          <span className={style.mainContainerText}>
            For Legal and Security Purposes:
          </span>
          We may use your information to comply with legal obligations, resolve
          disputes, or protect the rights and safety of our company, users, and
          others.
        </li>
      </ul>

      <h2 className={style.main}>3. Cookies and Tracking Technologies</h2>
      <p className={style.titleBody}>
        We use cookies and other tracking technologies to collect information
        automatically as you navigate through our Website. Cookies help us
        understand user behavior, personalize content, and improve the overall
        user experience. You can manage your cookie preferences in your browser
        settings. However, disabling cookies may affect the functionality of the
        Website.
      </p>

      <h2 className={style.main}>4. Sharing Your Information</h2>
      <p className={style.maxWidth}>
        We do not sell, trade, or rent your personal information to third
        parties. However, we may share your information with trusted third
        parties under the following circumstances:
      </p>
      <ul className={style.maxWidth}>
        <li>
          <span className={style.mainContainerText}>Service Providers:</span>
          We may share information with third-party vendors who perform services
          on our behalf, such as payment processors, analytics providers, and
          email service providers.
        </li>
        <div className="spacer-xs" />
        <li>
          <span className={style.mainContainerText}>Business Transfers:</span>:
          In the event of a merger, acquisition, or sale of assets, your
          information may be transferred as part of that transaction.
        </li>
        <div className="spacer" />
        <li>
          <span className={style.mainContainerText}>Legal Obligations:</span>
          We may disclose your information if required to do so by law, or in
          response to a valid legal request, such as a subpoena or court order.
        </li>
        <div className="spacer" />
        <li>
          <span className={style.mainContainerText}>Protection of Rights:</span>
          We may disclose your information when necessary to protect the rights,
          property, or safety of Commodified Media Ltd., our users, or others.
        </li>
      </ul>

      <h2 className={style.main}>5. Data Security</h2>
      <p className={style.titleBody}>
        We take reasonable precautions to protect your personal information from
        unauthorized access, use, or disclosure. While we strive to use
        commercially acceptable means to protect your data, no method of
        transmission over the Internet or method of electronic storage is 100%
        secure. Therefore, we cannot guarantee its absolute security.
      </p>

      <h2 className={style.main}>6. Your Rights and Choices</h2>
      <p className={style.maxWidth}>
        Depending on your location, you may have the following rights regarding
        your personal information:
      </p>
      <ul className={style.maxWidth}>
        <li>
          <span className={style.mainContainerText}>
            Access and Correction:
          </span>
          You can request access to or correction of your personal data that we
          hold.
        </li>
        <div className="spacer-xs" />
        <li>
          <span className={style.mainContainerText}>Data Deletion:</span>: : You
          can request that we delete your personal information, subject to legal
          requirements or obligations.
        </li>
        <div className="spacer" />
        <li>
          <span className={style.mainContainerText}>Opt-Out:</span>You can opt
          out of receiving marketing communications from us at any time by
          following the unsubscribe instructions provided in the communication
          or by contacting us directly
        </li>
        <div className="spacer" />
        <li>
          <span className={style.mainContainerText}>Data Portability:</span>
          In certain circumstances, you may have the right to receive a copy of
          your personal data in a structured, commonly used format.
        </li>
        <p>
          To exercise any of these rights, please contact us using the details
          provided below.
        </p>
      </ul>

      <h2 className={style.main}>7. Retention of Data</h2>
      <p className={style.titleBody}>
        We will retain your personal information for as long as is necessary to
        fulfill the purposes for which it was collected or to comply with legal
        requirements. When your information is no longer needed, we will
        securely delete or anonymize it.
      </p>

      <h2 className={style.main}>8. Third-Party Links</h2>
      <p className={style.titleBody}>
        Our Website may contain links to third-party websites or services that
        are not controlled by us. This Privacy Policy does not apply to
        third-party websites. We recommend reviewing the privacy policies of
        those websites before providing them with any personal information.
      </p>

      <h2 className={style.main}>9. Children's Privacy</h2>
      <p className={style.titleBody}>
        Our Website and services are not directed at individuals under the age
        of 16, and we do not knowingly collect personal information from
        children under this age. If you are a parent or guardian and believe
        that your child has provided us with personal data, please contact us.
        We will take steps to remove such information.
      </p>

      <h2 className={style.main}>10. International Data Transfers</h2>
      <p className={style.titleBody}>
        If you are accessing our Website from outside Gibraltar, your
        information may be transferred to, stored, and processed in countries
        other than your country of residence, including countries where data
        protection laws may differ. By using our Website, you consent to the
        transfer of your personal data to these countries.
      </p>

      <h2 className={style.main}>11. Changes to this Privacy Policy</h2>
      <p className={style.titleBody}>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or legal requirements. We will notify you of any
        significant changes by posting the updated policy on our Website. We
        encourage you to review this policy periodically
      </p>

      <h2 className={style.main}>12. Contact Us</h2>
      <p className={style.titleBody}>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at:
        <h4>Commodified Media Ltd.</h4>
      </p>
      <div className={"spacer-xs"} />
      <p>Suite 4.3.02, Block 4, Eurotowers</p>
      <a href="mailto:theoffice@commodifiedimage.com">
        theoffice@commodifiedimage.com
      </a>
      <div className="spacer" />
      <div className="spacer" />
    </div>
  );
}
