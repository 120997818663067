import React from "react";
import style from "./style.module.scss";
import { Link } from "react-router-dom";
/*
import Carousel from "components/Carousel/Carousel";

import img5 from "../../assets/HomePageImages/img5.png";
import img12 from "../../assets/DSP/img12.png";
import img14 from "../../assets/Performance/img14.png";
import img15 from "../../assets/Performance/img15.png";
*/
export default function Solutions({ hiddeButton = false }) {
  return (
    <div className={style.wrapper}>
      <div className="spacer" />
      <h1 className={`${style.titleBody}`}>Solutions</h1>
      {/* <h2>Advertisers</h2> */}
      <p className={style.titleBody}>
        We Collaborate with Everyone Who Shapes the Future of Advertising From
        DSPs and SSPs to agencies, trade desks, and app developers, Commodified
        media partners with innovators across the advertising spectrum.
      </p>
      <p className={style.titleBody}>
        No matter your place in the digital ecosystem, we’ve got the
        cutting-edge tools and AI-driven solutions to fuel your succes
      </p>
      <p className={style.titleBody}>
        Connect with us today to see how Commodified can power up your branding
        and app campaigns with precision and incremental impact.
      </p>
      {!hiddeButton && (
        <>
          <div className="spacer" />
          <Link className="contained-button">See How We Can Help</Link>
        </>
      )}
      <div className="spacer" />
      {/* <h1>Brand Advertisers</h1>
      <p className={style.titleBody}>
        We at Commodified amplify Brand messaging across premium channels, using
        advanced data analytics to create impactful, high-performance campaigns.
      </p>
      <div className="spacer" />
      <h1>Performance Advertisers</h1>
      <p className={style.titleBody}>
        We use data-driven targeting to connect your ads with high-intent
        audiences, increasing engagement and maximizing conversions.
      </p>
      {!hiddeButton && (
        <>
          <div className="spacer" />
          <Link className="contained-button">See How We Can Help</Link>
        </>
      )}
      <div className="spacer" />
      <h1>Performance Advertisers</h1>
      <div className="spacer" />
      <div className="spacer" />
      <Carousel images={[img5, img12, img14, img15]} />
      <div className="spacer" />
      <div className="spacer" />
      <Link className="contained-button">See How We Can Help</Link>
      <div class={style.gridContainer}>
        <div class={style.gridItem}>
          <h3>Banners</h3>
        </div>
        <div class={style.gridItem}>
          <h3>Video</h3>
        </div>
        <div class={style.gridItem}>
          <h3>CTV</h3>
        </div>
        <div class={style.gridItem}>
          <h3>Audio</h3>
        </div>
        <div class={style.gridItem}>
          <h3>Rewarded Video</h3>
        </div>
        <div class={style.gridItem}>
          <h3>Native</h3>
        </div>
      </div>
      {!hiddeButton && (
        <>
          <div className="spacer" />
          <Link className="contained-button">See How We Can Help</Link>
        </>
      )}
      <div className="spacer" />
      <h3>Publishers</h3>
      <p className={style.titleBody}>
        From header bidding to premium demand, we provide publishers with
        cutting-edge solutions to drive increased revenue and seamless ad
        delivery.
      </p>
      <p className={style.titleBody}>
        Our partnership gives you the tools to maximize every ad impression with
        smarter yield management, premium placements, and access to exclusive
        budgets.
      </p>
      <p className={style.titleBody}>
        Our solutions provide publishers with the opportunity to build
        sustainable, long-term revenue growth through advanced ad strategies and
        premium monetization opportunities.
      </p>
      <div className="spacer" />
      <div className="spacer" />*/}
    </div>
  );
}
