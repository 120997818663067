import React from "react";
import style from "./style.module.scss";
import { Link } from "react-router-dom";
//import Carousel from "components/Carousel/Carousel";

/*import img0 from "../../assets/DSP/img0.png";
import img1 from "../../assets/DSP/img1.png";
import img2 from "../../assets/DSP/img2.png";
import img3 from "../../assets/DSP/img3.png";
import img4 from "../../assets/DSP/img4.png";
import img5 from "../../assets/DSP/img5.png";
import img6 from "../../assets/DSP/img6.png";
import img7 from "../../assets/DSP/img7.png";
import img8 from "../../assets/DSP/img8.png";
import img9 from "../../assets/DSP/img9.png";
import img10 from "../../assets/DSP/img10.png";
import img11 from "../../assets/DSP/img11.png";
import img12 from "../../assets/DSP/img12.png";

import img13 from "../../assets/Performance/img13.png";
import img14 from "../../assets/Performance/img14.png";
import img15 from "../../assets/Performance/img15.png";
import img16 from "../../assets/Performance/img16.png";
import img17 from "../../assets/Performance/img17.png";
import img18 from "../../assets/Performance/img18.png";
import img19 from "../../assets/Performance/img19.png";
import img20 from "../../assets/Performance/img20.png";
import img21 from "../../assets/Performance/img21.png";
import img22 from "../../assets/Performance/img22.png";
import img23 from "../../assets/Performance/img23.png";
*/
export default function Performance({ hiddeButton = false }) {
  return (
    <div className={style.wrapper}>
      <div className="spacer" />
      <h1 className={`${style.titleBody} ${style.removeSpace}`}>Performance</h1>
      <h2 className={style.titleBody}>
        Achieve the Full Potential of Your Campaigns with AI-Driven Performance
      </h2>
      <p className={style.titleBody}>
        Enhance your campaign effectiveness with real-time performance tracking,
        continual optimization, and actionable insights.
      </p>
      <p className={style.titleBody}>
        Get granular insights into campaign performance and use data to refine
        strategies for better ROI.
      </p>
      <div className="spacer" />
      {!hiddeButton && (
        <>
          <Link className="contained-button">See How We Can Help</Link>
          <div className="spacer" />
        </>
      )}
      <div className="spacer" />
      {/* <Carousel
        images={[
          img0,
          img1,
          img2,
          img3,
          img4,
          img5,
          img6,
          img7,
          img8,
          img9,
          img10,
          img11,
          img12,
          img13,
          img14,
          img15,
          img16,
          img17,
          img18,
          img19,
          img20,
          img21,
          img22,
          img23,
        ]}
      /> */}
      {/* <div className="spacer" />
      <div className="spacer" /> */}
    </div>
  );
}
