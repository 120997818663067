import React from "react";
import Home from "../Home/Home";
import Solutions from "../Solutions/Solutions";
/*import Contact from "../Contacts/Contacts";*/
import Company from "../Company/Company";
import Dsp from "../../components/Products/Dsp";
import Performance from "../../components/Products/Performance";
import Marketplace from "../../components/Products/Marketplace";
import TravelSSP from "../../components/Products/TravelSSP";
import Technology from "components/Technology/Technology";

export default function MainPage() {
  return (
    <div>
      <Home />
      <Dsp hiddeButton />
      <Performance hiddeButton />
      <Marketplace hiddeButton />
      <TravelSSP />
      <Solutions hiddeButton />
      <Technology />
      {/* <Contact /> */}
      <Company hideTechnology />
    </div>
  );
}
