import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./style.module.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

export default function Carousel({
  images,
  squareShow = false,
  largeSize = false,
}) {
  return (
    <>
      <Swiper
        spaceBetween={300}
        //slidesPerView={3}
        loop={true} // Enable infinite loop
        autoplay={{
          delay: 0, // No delay for continuous scrolling
          disableOnInteraction: false, // Continue autoplay even after interaction
        }}
        speed={2000} // Adjust speed for smooth scrolling
        grabCursor={false}
        allowTouchMove={false}
        breakpoints={{
          // For large screens (desktop)
          1024: {
            slidesPerView: 3, // Show 3 slides on desktop
            spaceBetween: 30, // Adjust space between slides for desktop
          },
          // For tablets (width <= 1024px)
          768: {
            slidesPerView: 2, // Show 2 slides on tablets
            spaceBetween: 20, // Adjust space between slides for tablet
          },
          // For mobile (width <= 768px)
          480: {
            slidesPerView: 1, // Show 1 slide on mobile
            spaceBetween: 0, // No space between slides on mobile
          },
        }}
        modules={[Autoplay, Pagination]} // Include Autoplay module
        className="mySwiper"
      >
        {images.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                className={`${styles.images} ${squareShow ? styles.squareShow : ""} ${largeSize ? styles.largeSize : ""}`}
                src={image}
                alt={"Company Logo"}
              ></img>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
