import React from "react";
import Dsp from "../../components/Products/Dsp";
import Performance from "../../components/Products/Performance";
import Marketplace from "../../components/Products/Marketplace";
import TravelSSP from "../../components/Products/TravelSSP";
import { useLocation } from "react-router-dom";

export default function Product() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page");

  const renderPage = () => {
    switch (page) {
      case "dsp":
        return <Dsp />;
      case "performance":
        return <Performance />;
      case "marketplace":
        return <Marketplace />;
      case "travel":
        return <TravelSSP />;
      default:
        return <div>Page not found</div>;
    }
  };

  return <div>{renderPage()}</div>;
}
